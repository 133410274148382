import {NgStyle, NgTemplateOutlet} from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export interface GeneralCard {
  photoURL: string;
  title: string;
}

@Component({
  selector: 'ark-link-card',
  standalone: true,
  imports: [NgTemplateOutlet, NgStyle],
  templateUrl: './link-card.component.html',
  styleUrl: './link-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArkLinkCardComponent {
  @Input() width = '310px';
  @Input() generalCard: GeneralCard | null = null;

  @HostBinding('class.general-card') get generalCardClass() {
    return this.generalCard ? true : false;
  }

  @HostBinding('attr.draggable') draggable = true;
}
